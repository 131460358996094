<template>
	<div class="top_container" :class="menuType">
		<sticky-menu-popup v-if="showStickyMenu" :top-menu-hover="isMenuHover" :fetched-data="fetchedData" />
		<div class="top_menu" @mouseover="handleMenuMouseOver(true)" @mouseleave="handleMenuMouseOver(false)">
			<div :class="fluid ? 'container-fluid' : 'container'">
				<div class="menu_panel row no-gutters justify-content-between">
					<div class="col-auto left_part">
						<nuxt-link
							v-if="fetchedData.id === 8662 || fetchedData.id === 7792"
							:to="'/'"
							class="logo-khust"
						/>
						<nuxt-link
							v-else-if="fetchedData.id === 16725"
							:to="'/'"
							class="logo-atlanty"
						/>
						<nuxt-link
							v-else-if="fetchedData.id === 9162"
							:to="'/'"
							class="logo-yandex"
						/>
						<nuxt-link
							v-else-if="fetchedData.id === 18495"
							:to="'/'"
							class="logo-ProductCamp"
						/>
						<nuxt-link
							v-else-if="fetchedData.id === 4095"
							:to="'/'"
							class="logo-stories"
						/>

						<nuxt-link
							v-else-if="fetchedData.id === 19325"
							:to="'/'"
							class="logo-lean"
						/>

						<nuxt-link
							v-else
							:to="$i18n.locale === 'ru' ? '/' : '/en/'"
							class="logo"
							:aria-label="$t('pages.home')"
						/>
					</div>
					<div class="col-auto right_part">
						<div class="btn-panel">
							<a v-if="showLoyaltyInMenu" href="/loyalty-system/" class="d-none d-sm-block btn btn-sm btn-primary mr-4">
								{{ $t('loyaltyProgram') }}
							</a>
							<nuxt-link
								v-if="$auth.loggedIn"
								:to="{ name: 'user-profile', query: { item: 'MainInfo' }, params: { redirectUrl: $route.fullPath } }"
								class="d-flex align-items-center"
							>
								<div class="container d-none d-lg-block">
									<div class="text-right">
										{{ $t('hello') }}, {{ $auth.user.first_name || $auth.user.email }}
									</div>
									<div
										v-if="loyaltyProfile && !isLoyaltyProfileFetching"
										class="mt-1 text-right"
										style="font-size: 0.9rem"
									>
										{{ $t('bonusBalance') }}: {{ $points(roundedPointsSum) }}
									</div>
								</div>

								<b-img-lazy
									v-if="$auth.user && $auth.user.avatar_url"
									:src="$auth.user.avatar_url"
									blank-color="lightgray"
									style="object-fit: cover; width: 30px !important; height: 30px !important;"
									class="ml-1 rounded-circle align-self-center"
								/>
								<span
									v-if="$auth.user.activities > 0"
									class="ml-1 badge badge-danger badge-pill d-none d-lg-flex align-self-center"
								>
									{{ $auth.user.activities }}
								</span>
							</nuxt-link>
							<div v-else class="avatar">
								<a
									href="javascript:void(null);"
									:aria-label="$t('login')"
									@click="showLoginPopup"
								>
									<span class="icon icon-private d-flex align-self-center mr-4" />
								</a>
							</div>

							<a
								href="javascript:void(null);"
								:aria-label="$t('search')"
								class="d-none d-lg-flex"
								@click="$bvModal.show('search-modal')"
							>
								<span class="icon icon-search d-none d-lg-flex align-self-center" />
							</a>

							<!-- <nuxt-link
								v-if="products.length"
								:to="{ name: 'cart' }"
								class="d-none d-lg-flex align-items-center"
							>
								<cart-svg class="icon d-flex align-self-center" />
								<div class="icon-cart">
									{{ products.length }}
								</div>
							</nuxt-link> -->

							<a
								v-for="locale in availableLocales"
								:key="locale.code"
								role="button"
								class="lang_switch d-none d-lg-flex align-self-center"
								@click="$i18n.setLocale(locale.code)"
							>
								{{ locale.name }}
							</a>

							<a
								v-if="$i18n.locale ==='ru'"
								href="javascript:void(null);"
								:aria-label="$t('impaired')"
								class="d-none d-lg-flex"
								@click="visuallyImpair"
							>
								<span class="icon icon-eye bvi-open d-none d-lg-flex align-self-center" />
							</a>

							<button
								type="button"
								class="btn text-white menu_btn ml-lg-4 ml-0 icon-burger rounded-0"
								:aria-label="$t('openMenu')"
								@click="$bvModal.show('modal-menu')"
							/>

							<b-modal
								id="modal-menu"
								size="menu"
								hide-backdrop
								no-close-on-backdrop
								hide-footer
								no-fade
								dialog-class="theme-modal"
								header-border-variant="dark"
								scrollable
							>
								<template #modal-header="{ close }">
									<div class="container">
										<div class="row d-flex no-gutters justify-content-start btn-panel">
											<nuxt-link :to="'/'" class="logo d-none d-lg-block" />

											<!-- show in mobile (down sm) only -->
											<nuxt-link
												v-if="$auth.loggedIn"
												:to="{ name: 'user-profile', query: { item: 'MainInfo' }, params: { redirectUrl: $route.fullPath } }"
												class="d-lg-none"
											>
												<b-img-lazy
													v-if="$auth.user && $auth.user.avatar_url"
													:src="$auth.user.avatar_url"
													:alt="$auth.user.first_name + ' avatar'"
													blank-color="lightgray"
													width="30"
													class="rounded-circle d-md-inline"
												/>
												<span
													v-if="$auth.user.activities > 0"
													class="badge badge-danger badge-pill"
												>
													{{ $auth.user.activities }}
												</span>
											</nuxt-link>
											<div v-else class="avatar d-lg-none">
												<a
													href="javascript:void(null);"
													:aria-label="$t('login')"
													@click="showLoginPopup"
												>
													<span class="icon icon-private mr-4" />
												</a>
											</div>

											<div
												v-if="loyaltyProfile"
												class="order-last col-12 col-sm-auto order-sm-0 text-left d-lg-none my-2 mr-3"
											>
												{{ $t('bonusBalance') }}: {{ $points(roundedPointsSum) }}
											</div>

											<a
												href="javascript:void(null);"
												:aria-label="$t('searchVar')"
												@click="$bvModal.show('search-modal')"
											>
												<span class="icon icon-search mr-1" />
											</a>

											<template v-if="languageSwitchLink && !disableRedirect">
												<a
													v-for="locale in availableLocales"
													:key="locale.code"
													role="button"
													class="lang_switch"
													@click="$i18n.setLocale(locale.code)"
												>
													{{ locale.name }}
												</a>
											</template>

											<!-- Emulate built in modal header close button action -->
											<div class="col-auto ml-auto">
												<b-button
													variant="primary"
													class="btn-close icon-close rounded-0"
													@click="close()"
												/>
											</div>
										</div>
									</div>
								</template>
								<div class="container">
									<div class="row">
										<div v-if="$i18n.locale ==='ru'" class="col-12 col-sm-12 col-md-12 col-lg-12">
											<a href="/donation/" class="btn btn-md btn-primary">{{ $t('giveNow') }}</a>
										</div>
										<div v-if="showLoyaltyInMenu" class="mt-2 col-12 col-sm-12 col-md-12 col-lg-12">
											<a href="/loyalty-system/" class="d-md-none btn btn-md btn-primary">{{ $t('loyaltyProgram') }}</a>
										</div>
									</div>
									<div class="row mt-0">
										<div v-if="$i18n.locale ==='ru'" class="col-12 col-sm-12 col-md-12 col-lg-8 mt-4">
											<div class="title mb-3">
												<a href="/navigator/programmes/">Программы</a>
											</div>
											<ul class="list-unstyled modal-menu-list">
												<li>
													<a href="/programmes/global-advanced-management-program/">Global Advanced Programmes</a>
												</li>
												<li>
													<a href="/degree/">MBA программы</a>
												</li>
												<li>
													<a href="/school-student/">Программы для учащихся 8-11 классов</a>
												</li>
												<li>
													<a href="/bachelor-programmes/">Программы бакалавриата</a>
												</li>
												<li>
													<a href="/young-professionals/">Программы для молодых специалистов</a>
												</li>
												<li>
													<a href="/msb/">Программы для запуска и развития бизнеса</a>
												</li>
												<li>
													<a href="/leadership-competency-development/">Программы по лидерству и развитию профессиональных компетенций</a>
												</li>
												<li>
													<a href="/research-and-education/">Программы для отрасли образования</a>
												</li>
												<li>
													<a href="/healthcare-programs/">Программы для отрасли здравоохранения</a>
												</li>
												<li>
													<a href="/b2g-programmes/">Решения для государственного и общественного сектора</a>
												</li>
												<li>
													<a href="/centres/coaching/">Коучинг, менторство, карьерное консультирование</a>
												</li>
												<li><a href="/corporate/">Кастомизированные образовательные программы для компаний</a></li>
											</ul>
											<div class="mt-5">
												<h4 class="title">
													Не увидели подходящую программу?
												</h4>
												<p>Чтобы получить всю информацию об интересующей вас программе, свяжитесь с нашим консультантом или подберите программу</p>
											</div>
											<div class="my-4">
												<nuxt-link to="/navigator/programmes/" class="btn btn-primary mb-4 mr-3">
													Подобрать программу
												</nuxt-link>
												<nuxt-link to="/navigator/events/" class="btn btn-primary mb-4 mr-3">
													Все мероприятия
												</nuxt-link>
												<a
													role="button"
													onclick="return jivo_api.open(),!1"
													href="javascript:void(0)"
													class="btn btn-primary mb-4 mr-3"
												>{{ $t('ChatWithAdvisor') }}</a>
											</div>
										</div>
										<div v-if="$i18n.locale ==='en'" class="col-12 col-sm-12 col-md-12 col-lg-8 mt-4">
											<div class="title mb-3">
												<a href="/en/about-school/">{{ $t('aboutSchool') }}</a>
											</div>
											<ul class="list-unstyled modal-menu-list">
												<li v-b-toggle.management-collapse class="dropdown-toggle">
													{{ $t('OrganisationalStructure') }}
												</li>
												<b-collapse id="management-collapse" tag="li">
													<ul class="list-unstyled">
														<li><a href="/en/about-school/management/">{{ $t('Management') }}</a></li>
														<!--<li><a href="/en/about-school/management/board-of-directors/">{{ $t('BoardofDirectors') }}</a></li>-->
														<!--<li><a href="/en/about-school/management/international-advisory-board/">{{ $t('InternationalAdvisoryBoard') }}</a></li>-->
														<!--<li><a href="/en/about-school/management/international-academic-council/">{{ $t('InternationalAcademicCouncil') }}</a></li>-->
														<li><a href="/en/about-school/management/academic-committee/">{{ $t('AcademicCommittee') }}</a></li>
													</ul>
												</b-collapse>
												<!--<li><a href="/en/about-school/accreditations-and-partnerships/">{{ $t('accreditations') }}</a></li>-->
												<li><a href="/en/campus-rental/">{{ $t('campus') }}</a></li>
												<li><a href="/en/about-school/sustainability/">{{ $t('sustainability') }}</a></li>
												<li><a href="/en/about-school/code-of-ethics/">{{ $t('codeofEthics') }}</a></li>
												<li><a href="/en/about-school/contacts/">{{ $t('contacts') }}</a></li>
											</ul>
										</div>
										<div class="col-12 col-sm-12 col-lg-4 mt-4 mb-5">
											<ul v-if="$i18n.locale ==='en'" class="list-unstyled title">
												<li class="mb-3">
													<a href="/en/academic/academic-faculty/">{{ $t('professors') }}</a>
												</li>
												<li class="mb-3">
													<a href="/en/academic/academic-centres/">{{ $t('research') }}</a>
												</li>
												<!--<li class="mb-3">
													<a href="/en/navigator/events/">{{ $t('events') }}</a>
												</li>
												<li class="mb-3">
													<a href="/en/intrend/">{{ $t('news') }}</a>
												</li>-->
												<li class="mb-3">
													<a href="/en/alumni/">Alumni</a>
												</li>
											</ul>
											<ul v-else class="list-unstyled title">
												<div class="title mb-3">
													<a href="/about-school/">{{ $t('aboutSchool') }}</a>
												</div>
												<ul class="list-unstyled modal-menu-list">
													<li v-b-toggle.management-collapse class="dropdown-toggle">
														{{ $t('OrganisationalStructure') }}
													</li>
													<b-collapse id="management-collapse" tag="li">
														<ul class="list-unstyled">
															<li><a href="/about-school/management/">{{ $t('Management') }}</a></li>
															<!--<li><a href="/about-school/management/sovet-direktorov/">{{ $t('BoardofDirectors') }}</a></li>-->
															<!--<li><a href="/about-school/management/mezhdunarodnyj-popechitelskij-sovet/">{{ $t('InternationalAdvisoryBoard') }}</a></li>-->
															<!--<li><a href="/about-school/management/mezhdunarodnyj-akademicheskij-sovet/">{{ $t('InternationalAcademicCouncil') }}</a></li>-->
															<li><a href="/about-school/management/uchenyj-sovet/">{{ $t('AcademicCommittee') }}</a></li>
														</ul>
													</b-collapse>
													<!--<li><a href="/about-school/history/">{{ $t('history') }}</a></li>-->
													<!--<li><a href="/about-school/accreditations-and-partnerships/">{{ $t('accreditations') }}</a></li>-->
													<li><a href="/campus-rental/">{{ $t('campus') }}</a></li>
													<li><a href="https://shop.skolkovo.ru/">Skolkovo Shop</a></li>

													<li><a href="/about-school/sustainability/">{{ $t('sustainability') }}</a></li>
													<li><a href="/about-school/code-of-ethics/">{{ $t('codeofEthics') }}</a></li>
													<li><a href="/about-school/contacts/">{{ $t('contacts') }}</a></li>
													<li v-if="isMenuVisible">
														<a href="https://bbask.ru/sveden/">{{ $t('InformationAboutOrganization') }}</a>
													</li>
													<li v-else>
														<a href="https://www.skolkovo.ru/sveden/">{{ $t('InformationAboutOrganization') }}</a>
													</li>
												</ul>
												<li class="mb-3">
													<a href="/academic/academic-faculty/">{{ $t('professors') }}</a>
												</li>
												<li class="mb-3">
													<a href="/academic/academic-centres/">{{ $t('research') }}</a>
												</li>
												<!-- <li class="mb-3">
													<a href="/navigator/events/">{{ $t('events') }}</a>
												</li> -->
												<li class="mb-3">
													<a href="/intrend/">inTrend</a>
												</li>
												<li class="mb-3">
													<a href="/alumni/">Alumni</a>
												</li>
												<li class="mb-3">
													<a href="/loyalty-system/">{{ $t('loyaltyProgram') }}</a>
												</li>
											</ul>
										</div>
									</div>
								</div>
							</b-modal>
						</div>
					</div>

					<!-- <nuxt-link :to="switchLocalePath('ru')">Italian</nuxt-link>
					<nuxt-link :to="switchLocalePath('en')">English</nuxt-link>-->
				</div>
			</div>
		</div>
		<div v-if="!route && !hideSubmenu" class="top_submenu d-none d-lg-block" :style="topSubmenuStyle">
			<intersect @enter.once="onVisible">
				<div class="container">
					<div class="row justify-content-between align-items-center">
						<div class="col">
							<ul v-if="$i18n.locale ==='en'" class="menu">
								<li><a href="/en/about-school/">{{ $t('aboutSchool') }}</a></li>
								<li><a href="/en/programmes/">{{ $t('programmes') }}</a></li>
								<li><a href="/en/academic/academic-faculty/">{{ $t('professors') }}</a></li>
								<li><a href="/en/academic/academic-centres/">{{ $t('research') }}</a></li>
								<li><a href="/en/alumni/">Alumni</a></li>
							</ul>
							<ul v-else class="menu">
								<li><a href="/about-school/">{{ $t('aboutSchool') }}</a></li>
								<li><a href="/navigator/programmes/?typologies=1">{{ $t('programmes') }}</a></li>
								<li><a href="/academic/academic-faculty/">{{ $t('professors') }}</a></li>
								<li><a href="/academic/academic-centres/">{{ $t('research') }}</a></li>
								<li><a href="/navigator/events/">{{ $t('events') }}</a></li>
								<li><a href="/intrend/">inTrend</a></li>
								<li><a href="/alumni/">Alumni</a></li>

							<!-- <li><a href="https://canvas.skolkovo.ru/login/canvas">{{ $t('students') }}</a></li>
							<li><a href="https://alumni.skolkovo.ru/">{{ $t('alumni') }}</a></li>
							<li><a href="https://sk.skolkovo.ru/login">{{ $t('staff') }}</a></li> -->
							</ul>
						</div>
						<div class="col-auto">
							<a v-if="$i18n.locale ==='en'" href="/en/donation/" class="btn btn-lg btn-primary">
								{{ $t('giveNow') }}
							</a>
							<a v-else href="/donation/" class="btn btn-lg btn-primary">
								{{ $t('giveNow') }}
							</a>
						</div>
					</div>
				</div>
			</intersect>
		</div>
	</div>
</template>

<script>
import _cloneDeep from 'lodash/cloneDeep';
import Intersect from 'vue-intersect';
import {
	mapActions, mapState, mapMutations, mapGetters,
} from 'vuex';
import CartSvg from '~/static/svg/cartSvg.vue';
import StickyMenuPopup from '~/components/StickyMenuPopup.vue';

const firstIdArr = [18435];

export default {
	name: 'TopMenuNew',
	components: { StickyMenuPopup, CartSvg, Intersect },
	inject: {
		fetchedDataEventPage: { value: 'fetchedDataEventPage', default: null },
	},
	props: {
		fetchedData: { type: Object, default: () => ({}) },
		disableRedirect: { type: Boolean, default: false },
		menuType: { type: String, default: '' },
		hideSubmenu: { type: Boolean, default: false },
		fluid: { type: Boolean, default: false },
	},
	data() {
		return {
			topSubmenuStyle: null,
			isMenuVisible: false,
			loyaltyProfile: null,
			isMenuHover: false,
			isLoyaltyProfileFetching: false,
		};
	},
	computed: {
		...mapState({
			products: (state) => _cloneDeep(state.cart.products),
		}),
		...mapGetters({
			isAlumni: 'profile/isAlumni',
			isUserPointsChanged: 'loyalty/isUserPointsChanged',
		}),
		availableLocales() {
			return this.$i18n.locales.filter((i) => i.code !== this.$i18n.locale);
		},
		languageSwitchLink() {
			return this.fetchedData.alternative_language_version || null;
		},
		...mapState('visuallyImpair', {
			isVisuallyImpairLoaded: ({ isLoaded }) => isLoaded,
		}),
		route() {
			return this.$route.path.startsWith('/user/') || this.$route.path.startsWith('/cart/') || this.$route.path.startsWith('/order/') || this.$route.path.startsWith('/constructor/');
		},
		roundedPointsSum() {
			return this.loyaltyProfile ? Math.round(this.loyaltyProfile.points_sum) : 0;
		},
		showStickyMenu() {
			const isAlumniRoute = this.$route.path.split('/')[1] === 'alumni';
			// опрос завершен, пока скрыл - to do динамика нужна
			// return this.isAlumni && (this.$route.name === 'index' || isAlumniRoute);
		},
		showLoyaltyInMenu() {
			return this.$route.path === '/';
		},
	},
	watch: {
		isUserPointsChanged: {
			async handler() {
				if (!this.isUserPointsChanged) {
					return;
				}

				try {
					this.isLoyaltyProfileFetching = true;

					await this.getLoyaltyProfile();
					this.setIsUserPointsChanged(false);
				} finally {
					this.isLoyaltyProfileFetching = false;
				}
			},
		},
		'$auth.user': {
			handler(user) {
				if (user) {
					this.getLoyaltyProfile();
				}
			},
			immediate: true,
		},
		'fetchedData.alternative_language': {
			handler(alt) {
				if (this.fetchedData.alternative_language_version) {
					if (alt === 'rus' && this.$i18n.locale !== 'en') {
						this.$i18n.setLocale('en');
					} else if (!alt && this.$i18n.locale !== 'ru') {
						this.$i18n.setLocale(this.$i18n.locale);
					}
				}
			},
			immediate: true,
		},
		'$i18n.locale': {
			// eslint-disable-next-line complexity
			handler(lang, old) {
				if (this.fetchedDataEventPage && (
					(this.fetchedDataEventPage.alternative_language === 'rus' && lang === 'ru')
					|| (this.fetchedDataEventPage.alternative_language === 'eng' && lang === 'en'))) {
					const altRoute = `${this.fetchedDataEventPage.event.alternative_language_version}${this.fetchedDataEventPage.alternative_language_version ?? ''}`;
					this.$router.push({ path: altRoute });
				} else if (!this.fetchedDataEventPage) {
					const alt = this.fetchedData.alternative_language;
					if (alt && this.languageSwitchLink && lang !== old
						&& !this.disableRedirect) {
						if ((alt === 'rus' && lang !== 'en') || (alt === 'eng' && lang !== 'ru')) {
							this.$router.push({ path: this.languageSwitchLink });
						}
					}
				}
			},
		},
	},
	created() {
		const isLocal = this.$route?.name?.search('__en');
		if (isLocal > 0 && this.$i18n.locale === 'ru') {
			this.$i18n.setLocale('en');
		}
	},
	mounted() {
		if (this.$route.name === 'company-auth' && !this.$auth.loggedIn) {
			this.showRegistration();
			this.setClientState(2);
		}
		this.isMenuVisible = firstIdArr.includes(this.fetchedData.id);
	},
	methods: {
		...mapActions({
			showLoginPopup: 'authModal/showLogin',
			loadVisuallyImpair: 'visuallyImpair/load',
			showRegistration: 'authModal/showRegistration',
		}),
		...mapMutations({
			setClientState: 'authModal/setClientState',
			setIsUserPointsChanged: 'loyalty/setIsUserPointsChanged',
		}),
		visuallyImpair(event) {
			const close = document.getElementById('bvi-panel-close');
			if (close) {
				close.click();
			} else if (!this.isVisuallyImpairLoaded) {
				this.loadVisuallyImpair(event.target);
			}
		},
		onVisible() {
			if (this.$route.name === 'index') {
				this.topSubmenuStyle = "background-image:url('/frontimg/bg-main-top.jpg')";
			}
		},
		async getLoyaltyProfile() {
			const { data } = await this.$api.getLoyaltyProfile();

			if (data) {
				this.loyaltyProfile = data;
			}
		},
		switchLanguage(language) {
			this.$i18n.setLocale(language);
			const altRoute = this.fetchedDataEventPage.event.alternative_language_version + this.fetchedDataEventPage.alternative_language_version;
			this.$router.push({ path: altRoute });
		},
		handleMenuMouseOver(value) {
			this.isMenuHover = value;
		},
	},
};
</script>

<style lang="scss" scoped>
	@import "~/assets/styles/components/layouts/top-menu-new";
</style>
